<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.datosGenerales.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.datosGenerales.title }}
          <small>{{ steps.datosGenerales.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.datosGenerales.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="descripcion"
            :rules="formRules.descripcion"
          >
            <v-text-field
              v-model="form.descripcion"
              clearable
              flat
              label="Descripción"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-textarea
            v-model="form.observaciones"
            label="Observaciones"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="serie_operacioncrm"
            :rules="formRules.serie_operacioncrm"
          >
            <b10-autocomplete
              v-model="form.serie_operacioncrm"
              :items="seriesOperacionCRM"
              item-text="descripcorta"
              label="Serie"
              clearable
              :error-messages="errors"
              return-object
              no-filter
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="toperacioncrm"
            :rules="formRules.toperacioncrm"
          >
            <b10-autocomplete
              v-model="form.toperacioncrm"
              :items="tiposOperacionCRM"
              item-text="descripcion"
              label="Tipo"
              clearable
              :error-messages="errors"
              return-object
              no-filter
              @change="toperacionCRMSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="est_operacioncrm"
            :rules="formRules.idest_operacioncrm"
          >
            <b10-autocomplete
              v-model="form.idest_operacioncrm"
              :items="estadosPorTipoDeOperacionCRM"
              item-text="descripcion"
              item-value="idest_operacioncrm"
              label="Estado"
              clearable
              :error-messages="errors"
              no-filter
              @change="estadoSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="formRules.idprioridad"
          >
            <b10-autocomplete
              v-model="form.idprioridad"
              :items="prioridades"
              item-text="descripcion"
              item-value="idprioridad"
              label="Prioridad"
              clearable
              :error-messages="errors"
              no-filter
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.iddelegacion"
            :items="delegaciones"
            item-text="descripcion"
            item-value="iddelegacion"
            label="Delegación"
            clearable
            no-filter
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <!-- Planificación -->
        <v-stepper-step
          :step="steps.planificacion.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.planificacion.title }}
          <small>{{ steps.planificacion.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.planificacion .id"
        >
          <b10-autocomplete
            v-model="form.iddepartamento"
            :items="departamentos"
            item-text="descripcion"
            item-value="iddepartamento"
            label="Departamento"
            clearable
          />
          <b10-autocomplete
            v-model="form.idgrupo_empleado"
            :items="gruposEmpleado"
            placeholder="Escribe para buscar..."
            :append-icon="null"
            hide-no-data
            clearable
            item-text="descripcion"
            item-value="idgrupo_empleado"
            label="Responsables"
          />
          <ValidationProvider
            v-slot="{ errors }"
            :rules="formRules.fproximarevision"
          >
            <b10-date-time-picker
              v-model="form.fproximarevision"
              title="Fecha de próxima revisión"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-date-time-picker
            v-model="form.fprevistafin"
            title="Fecha prevista de fin"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <!-- cita -->
        <v-stepper-step
          v-if="isInsert"
          :step="steps.cita.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.cita.title }}
          <small>{{ steps.cita.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.cita.id"
        >
          <b10-checkbox
            v-model="form.cita"
            label="Introducir cita"
            name="cita"
            :with-validation-provider="true"
            @change="citaSelected"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="cita_fecha"
            :rules="formRules.cita_fecha"
          >
            <b10-date-picker
              ref="citaFecha"
              v-model="form.cita_fecha"
              title="Fecha"
              :disabled="!form.cita"
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-time-picker
            ref="citaFecha"
            v-model="form.cita_inicio"
            title="Inicio"
            :disabled="!form.cita"
            :validation-rules="formRules.cita_inicio"
            :with-validation-provider="true"
          />
          <b10-time-picker
            ref="citaFecha"
            v-model="form.cita_fin"
            title="Fin"
            :disabled="!form.cita"
            :with-validation-provider="true"
            :validation-rules="formRules.cita_fin"
          />
          <b10-text-field
            v-model="form.cita_titulo"
            clearable
            flat
            label="Descripción"
            :disabled="!form.cita"
            :with-validation-provider="true"
            :validation-rules="formRules.cita_titulo"
          />
          <b10-textarea
            v-model="form.cita_cita"
            :disabled="!form.cita"
            label="Observaciones"
          />
        </v-stepper-content>
      </ValidationObserver>
      <!-- Datos -->
      <tipo-dato-step-form
        v-if="isInsert"
        v-show="idmtdato"
        :idmtdato="idmtdato"
        :form-step-id="maxStepID + 1"
        @change-component-values="changeTipoDatoStepFormData"
      />
    </v-stepper>
  </b10-base>
</template>

<script>
import TipoDatoStepForm from '@/pages/components/TipoDatoStepForm'
import formMixin from '@/mixins/formMixin'
import Data from './OperacioncrmFormData'
import _ from '@/utils/lodash'
import { PARAMETRO } from '@/utils/consts'
import  { extend } from 'vee-validate'

export default {
  components: {
    TipoDatoStepForm
  },
  mixins: [formMixin],
  props: {
    tabla: {
      type: Object,
      default: () => {},
    },
    idempleado: {
      type: Number,
      default: null
    },
    iddelegacion: {
      type: Number,
      default: null
    },
    esTecnico: {
      type: Boolean,
    },
    esComercial: {
      type: Boolean,
    },
    esVigilante: {
      type: Boolean,
    },
    descripcion: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      form: {
        descripcion: this.descripcion,
        serie_operacioncrm: null,
        toperacioncrm: null,
        idest_operacioncrm: null,
        iddelegacion: null,
        iddepartamento: null,
        idgrupo_empleado: null,
        fproximarevision: null,
        fprevistafin: null,
        observaciones: null,
        idprioridad: null,
        // cita
        cita: false,
        cita_fecha: null,
        cita_inicio: null,
        cita_fin: null,
        cita_titulo: null,
        cita_cita: null,
      },
      formRules: {
        descripcion: { required: true },
        serie_operacioncrm: { required: true },
        toperacioncrm: { required: true },
        idest_operacioncrm: { required: true },
        descripcion: { required: true },
        fproximarevision: { required: true },
        idprioridad: { required: true },
        cita_fecha: { required_if: { target: 'cita', value: true } },
        cita_inicio: { required_if: { target: 'cita', value: true } },
        cita_fin: { fechaFinValida: true },
        cita_titulo: { required_if: { target: 'cita', value: true } },
      },
      steps: {
        datosGenerales: {
          id: 1,
          title: 'Datos generales',
          subtitle: 'Datos generales y configuración de la operación',
        },
        planificacion: {
          id: 2,
          title: 'Planificación',
          subtitle: 'Asignación de fechas y responsable',
        },
        cita: {
          id: 3,
          title: 'Nueva cita',
          subtitle: 'Crear una cita relacionada con la operación',
        },
      },
      currentStep: 1,
      seriesOperacionCRM: [],
      tiposOperacionCRM: [],
      estadosOperacionCRM: [],
      departamentos: [],
      estadosPorTipoDeOperacionCRM: [],
      grupoEmpleadoPorTipoDeOperacionCRM: [],
      gruposEmpleado: [],
      prioridades: [],
      delegaciones: [],
      tiposContacto: [],
      canelesComunicacion: [],
      idmtdato: null,
    }
  },
  computed: {
    maxStepID () {
      return Math.max(..._.map(this.steps, 'id'))
    },
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.IDPRIORIDAD_DEFECTO_CRM,
      PARAMETRO.parametros.SERIE_OPERACION_CRM_DEFECTO,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios, this.esTecnico, this.esComercial, this.esVigilante)
    this.seriesOperacionCRM = resp.data.selectSerieOperacionCRM.result.dataset
    this.tiposOperacionCRM = resp.data.selectToperacionCRM.result.dataset
    this.estadosOperacionCRM = resp.data.selectEstadoOperacionCRM.result.dataset
    this.departamentos = resp.data.selectDepartamento.result.dataset
    this.prioridades = resp.data.selectvOperacioncrmPrioridad.result.dataset
    this.delegaciones = resp.data.selectDelegacion.result.dataset
    this.tiposContacto = resp.data.selectTcontacto.result.dataset
    this.canelesComunicacion = resp.data.selectCanalComunicacion.result.dataset
    this.gruposEmpleado = resp.data.selectGrupoEmpleado.result.dataset
    if (this.isInsert) {
      const parametroPrioridaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDPRIORIDAD_DEFECTO_CRM })
      if (parametroPrioridaDefecto) {
        const existePrioridadDefecto = _.find(this.prioridades, { idprioridad: parseInt(parametroPrioridaDefecto.valor) })
        if (existePrioridadDefecto) {
          this.$set(this.form, 'idprioridad', parseInt(parametroPrioridaDefecto.valor))
        }
      }
      const parametroSeriePorDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.SERIE_OPERACION_CRM_DEFECTO })
      if (parametroSeriePorDefecto) {
        const existeSeriePorDefecto = _.find(this.seriesOperacionCRM, { idserie_operacioncrm: parseInt(parametroSeriePorDefecto.valor) })
        if (existeSeriePorDefecto) {
          this.form.serie_operacioncrm = _.find(this.seriesOperacionCRM, { 'idserie_operacioncrm': parseInt(parametroSeriePorDefecto.valor) })
        }
      }
      this.$set(this.form, 'idinformador', this.idempleado)
      this.$set(this.form, 'iddelegacion', this.iddelegacion)
    }
    extend('fechaFinValida', {
      message: 'La fecha de fin debe ser posterior a la de inicio.',
      validate: async (value) => {
        if (value && value < this.form.cita_inicio) {
          return false
        } else {
          return true
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    async estadoSelected (value) {
      if (value){
        const tipoOperacionGrupoEmpleado = _.find(this.grupoEmpleadoPorTipoDeOperacionCRM, { 'idest_operacioncrm': value })
        if (tipoOperacionGrupoEmpleado?.idgrupo_empleado) {
          this.$set(this.form, 'idgrupo_empleado', tipoOperacionGrupoEmpleado.idgrupo_empleado)
        }
        if (tipoOperacionGrupoEmpleado?.iddepartamento) {
          this.$set(this.form, 'iddepartamento', tipoOperacionGrupoEmpleado.iddepartamento)
        }
      }
    },
    async establecerEstadoInicial () {
      if (this.estadosPorTipoDeOperacionCRM.length > 0) {
        const estadoInicial = _.find(this.estadosPorTipoDeOperacionCRM, {estado_inicial: true})
        if (estadoInicial) {
          this.$set(this.form, 'idest_operacioncrm', estadoInicial.idest_operacioncrm)
          if (this.isInsert) {
            // forzar evento
            this.estadoSelected(estadoInicial.idest_operacioncrm)
          }
        }
      } else {
        this.$alert.showSnackbarError('No existe ningún estado inicial para este tipo de operación')
      }
    },
    async toperacionCRMSelected (tipoOpercionCRM){
      if (tipoOpercionCRM) {
        const resp = await Data.selectLookupsTipoOperacionCRM(this, tipoOpercionCRM.idtoperacioncrm)
        this.$set(this, 'idmtdato', parseInt(tipoOpercionCRM.idmtdato))
        this.estadosPorTipoDeOperacionCRM = resp.data.selectEstadoOperacionCRM.result.dataset
        this.grupoEmpleadoPorTipoDeOperacionCRM = resp.data.selectToperacioncrmGrupoEmp.result.dataset
        this.establecerEstadoInicial()
        if (this.isInsert) {
          this.$set(this.form, 'iddepartamento', tipoOpercionCRM.iddepartamento)
        }
      } else {
        this.estadosPorTipoDeOperacionCRM = []
        this.grupoEmpleadoPorTipoDeOperacionCRM = []
        this.$set(this.form, 'idest_operacioncrm', null)
        this.$set(this.form, 'iddepartamento', null)
      }
    },
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      formData.toperacioncrm = _.find(this.tiposOperacionCRM, { 'idtoperacioncrm': formData.idtoperacioncrm })
      formData.serie_operacioncrm = _.find(this.seriesOperacionCRM, { 'idserie_operacioncrm': formData.idserie_operacioncrm })
      this.$set(this.form, 'idgrupo_empleado', formData.idgrupo_empleado)
      // forzar evento
      await this.toperacionCRMSelected(formData.toperacioncrm)
      this.form = formData
    },
    citaSelected (value) {
      if (!value) {
        this.$set(this.form, 'cita_fecha', null)
        this.$set(this.form, 'cita_inicio', null)
        this.$set(this.form, 'cita_fin', null)
        this.$set(this.form, 'cita_titulo', null)
      }
    },
    async changeTipoDatoStepFormData(data) {
      this.$set(this.form, 'datos', data)
    },
  },
}
</script>
