import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectSerieOperacionCRM', method: 'serieOperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEstadoOperacionCRM', method: 'estOperacioncrm.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectDepartamento', method: 'departamento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectDelegacion', method: 'delegacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTcontacto', method: 'tcontacto.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalComunicacion', method: 'canalComunicacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectGrupoEmpleado', method: 'grupoEmpleado.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    // vistas
    apiCalls.push({ name: 'selectvOperacioncrmPrioridad', method: 'vOperacioncrmPrioridad.select' })
    // Tipos de operacion
    const apiFilterToperacioncrm = Vue.$online.toperacioncrm.buildApiFilterVisualizacion(esTecnico, esComercial, esVigilante)
    apiCalls.push({ name: 'selectToperacionCRM', method: 'toperacioncrm.select', params: { filter: apiFilterToperacioncrm } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idoperacioncrm', id)
    const resp = await Vue.$api.call('operacioncrm.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectLookupsTipoOperacionCRM (Vue, idtoperacioncrm) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('idtoperacioncrm', idtoperacioncrm)
    apiCalls.push({ name: 'selectEstadoOperacionCRM', method: 'estOperacioncrm.select', params: { filter: apiFilter } })
    apiCalls.push({ name: 'selectToperacioncrmGrupoEmp', method: 'toperacioncrmGrupoEmp.select', params: { filter: apiFilter } })
    return await Vue.$api.batchCall(apiCalls)
  },
}