var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('v-stepper',{staticClass:"elevation-0",attrs:{"vertical":"","non-linear":"","value":_vm.currentStep}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [_c('v-stepper-step',{attrs:{"step":_vm.steps.datosGenerales.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.datosGenerales.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.datosGenerales.subtitle))])]),_c('v-stepper-content',{attrs:{"step":_vm.steps.datosGenerales.id}},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":_vm.formRules.descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción","error-messages":errors},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}})]}}],null,true)}),_c('b10-textarea',{attrs:{"label":"Observaciones"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}}),_c('ValidationProvider',{attrs:{"name":"serie_operacioncrm","rules":_vm.formRules.serie_operacioncrm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.seriesOperacionCRM,"item-text":"descripcorta","label":"Serie","clearable":"","error-messages":errors,"return-object":"","no-filter":""},model:{value:(_vm.form.serie_operacioncrm),callback:function ($$v) {_vm.$set(_vm.form, "serie_operacioncrm", $$v)},expression:"form.serie_operacioncrm"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"toperacioncrm","rules":_vm.formRules.toperacioncrm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tiposOperacionCRM,"item-text":"descripcion","label":"Tipo","clearable":"","error-messages":errors,"return-object":"","no-filter":""},on:{"change":_vm.toperacionCRMSelected},model:{value:(_vm.form.toperacioncrm),callback:function ($$v) {_vm.$set(_vm.form, "toperacioncrm", $$v)},expression:"form.toperacioncrm"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"est_operacioncrm","rules":_vm.formRules.idest_operacioncrm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.estadosPorTipoDeOperacionCRM,"item-text":"descripcion","item-value":"idest_operacioncrm","label":"Estado","clearable":"","error-messages":errors,"no-filter":""},on:{"change":_vm.estadoSelected},model:{value:(_vm.form.idest_operacioncrm),callback:function ($$v) {_vm.$set(_vm.form, "idest_operacioncrm", $$v)},expression:"form.idest_operacioncrm"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":_vm.formRules.idprioridad},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.prioridades,"item-text":"descripcion","item-value":"idprioridad","label":"Prioridad","clearable":"","error-messages":errors,"no-filter":""},model:{value:(_vm.form.idprioridad),callback:function ($$v) {_vm.$set(_vm.form, "idprioridad", $$v)},expression:"form.idprioridad"}})]}}],null,true)}),_c('b10-autocomplete',{attrs:{"items":_vm.delegaciones,"item-text":"descripcion","item-value":"iddelegacion","label":"Delegación","clearable":"","no-filter":""},model:{value:(_vm.form.iddelegacion),callback:function ($$v) {_vm.$set(_vm.form, "iddelegacion", $$v)},expression:"form.iddelegacion"}})],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [_c('v-stepper-step',{attrs:{"step":_vm.steps.planificacion.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.planificacion.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.planificacion.subtitle))])]),_c('v-stepper-content',{attrs:{"step":_vm.steps.planificacion .id}},[_c('b10-autocomplete',{attrs:{"items":_vm.departamentos,"item-text":"descripcion","item-value":"iddepartamento","label":"Departamento","clearable":""},model:{value:(_vm.form.iddepartamento),callback:function ($$v) {_vm.$set(_vm.form, "iddepartamento", $$v)},expression:"form.iddepartamento"}}),_c('b10-autocomplete',{attrs:{"items":_vm.gruposEmpleado,"placeholder":"Escribe para buscar...","append-icon":null,"hide-no-data":"","clearable":"","item-text":"descripcion","item-value":"idgrupo_empleado","label":"Responsables"},model:{value:(_vm.form.idgrupo_empleado),callback:function ($$v) {_vm.$set(_vm.form, "idgrupo_empleado", $$v)},expression:"form.idgrupo_empleado"}}),_c('ValidationProvider',{attrs:{"rules":_vm.formRules.fproximarevision},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-time-picker',{attrs:{"title":"Fecha de próxima revisión","error-messages":errors},model:{value:(_vm.form.fproximarevision),callback:function ($$v) {_vm.$set(_vm.form, "fproximarevision", $$v)},expression:"form.fproximarevision"}})]}}],null,true)}),_c('b10-date-time-picker',{attrs:{"title":"Fecha prevista de fin"},model:{value:(_vm.form.fprevistafin),callback:function ($$v) {_vm.$set(_vm.form, "fprevistafin", $$v)},expression:"form.fprevistafin"}})],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(slotObserverProps){return [(_vm.isInsert)?_c('v-stepper-step',{attrs:{"step":_vm.steps.cita.id,"editable":"","rules":[function (value) { return !slotObserverProps.validated || slotObserverProps.valid; }]}},[_vm._v(" "+_vm._s(_vm.steps.cita.title)+" "),_c('small',[_vm._v(_vm._s(_vm.steps.cita.subtitle))])]):_vm._e(),_c('v-stepper-content',{attrs:{"step":_vm.steps.cita.id}},[_c('b10-checkbox',{attrs:{"label":"Introducir cita","name":"cita","with-validation-provider":true},on:{"change":_vm.citaSelected},model:{value:(_vm.form.cita),callback:function ($$v) {_vm.$set(_vm.form, "cita", $$v)},expression:"form.cita"}}),_c('ValidationProvider',{attrs:{"name":"cita_fecha","rules":_vm.formRules.cita_fecha},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-picker',{ref:"citaFecha",attrs:{"title":"Fecha","disabled":!_vm.form.cita,"error-messages":errors},model:{value:(_vm.form.cita_fecha),callback:function ($$v) {_vm.$set(_vm.form, "cita_fecha", $$v)},expression:"form.cita_fecha"}})]}}],null,true)}),_c('b10-time-picker',{ref:"citaFecha",attrs:{"title":"Inicio","disabled":!_vm.form.cita,"validation-rules":_vm.formRules.cita_inicio,"with-validation-provider":true},model:{value:(_vm.form.cita_inicio),callback:function ($$v) {_vm.$set(_vm.form, "cita_inicio", $$v)},expression:"form.cita_inicio"}}),_c('b10-time-picker',{ref:"citaFecha",attrs:{"title":"Fin","disabled":!_vm.form.cita,"with-validation-provider":true,"validation-rules":_vm.formRules.cita_fin},model:{value:(_vm.form.cita_fin),callback:function ($$v) {_vm.$set(_vm.form, "cita_fin", $$v)},expression:"form.cita_fin"}}),_c('b10-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción","disabled":!_vm.form.cita,"with-validation-provider":true,"validation-rules":_vm.formRules.cita_titulo},model:{value:(_vm.form.cita_titulo),callback:function ($$v) {_vm.$set(_vm.form, "cita_titulo", $$v)},expression:"form.cita_titulo"}}),_c('b10-textarea',{attrs:{"disabled":!_vm.form.cita,"label":"Observaciones"},model:{value:(_vm.form.cita_cita),callback:function ($$v) {_vm.$set(_vm.form, "cita_cita", $$v)},expression:"form.cita_cita"}})],1)]}}])}),(_vm.isInsert)?_c('tipo-dato-step-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.idmtdato),expression:"idmtdato"}],attrs:{"idmtdato":_vm.idmtdato,"form-step-id":_vm.maxStepID + 1},on:{"change-component-values":_vm.changeTipoDatoStepFormData}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }